<template>
	<div class="error-modal">
		<div class="error-modal__blanket"></div>
		<div class="error-modal__card card">
			<h2 v-if="title">{{ title }}</h2>
			<p v-if="message">{{ message }}</p>
			<BasicButton v-if="button" buttonStyle="primary" :text="button" @click="$emit('close')" />
			<div class="caption" v-if="caption">{{ caption }}</div>
		</div>
	</div>
</template>

<script>
import BasicButton from '@/components/bits/BasicButton'

export default {
	name: 'ErrorModal',
	components: {
		BasicButton
	},
	emits: ['close'],
	props: {
		title: String,
		message: String,
		caption: String,
		button: {
			type: [String, Boolean],
			default: false
		}
	}
}
</script>
