<template>
	<svg class="icon" xmlns="http://www.w3.org/2000/svg" width="66" height="66" viewBox="0 0 66 66" overflow="visible">
		<g v-html="icon" vector-effect="non-scaling-stroke" />
	</svg>
</template>

<script>
const icons = {
	menu: `<line vector-effect="non-scaling-stroke" x1="0" y1="10.5" x2="66" y2="10.5"/>
		<line vector-effect="non-scaling-stroke" x1="0" y1="25.5" x2="66" y2="25.5"/>
		<line vector-effect="non-scaling-stroke" x1="0" y1="40.5" x2="66" y2="40.5"/>
		<line vector-effect="non-scaling-stroke" x1="0" y1="55.5" x2="44" y2="55.5"/>`,
	locale: `<circle vector-effect="non-scaling-stroke" cx="33" cy="33" r="31"/>
		<line vector-effect="non-scaling-stroke" x1="2.26" y1="33" x2="63.74" y2="33"/>
		<line vector-effect="non-scaling-stroke" x1="33" y1="2.26" x2="33" y2="63.74"/>
		<ellipse vector-effect="non-scaling-stroke" cx="33" cy="33" rx="16.08" ry="31"/>`,
	warning: `<path vector-effect="non-scaling-stroke" d="M38.86,12.38c0,0-5.95-10.42-11.9,0L6.72,47.83c0,0-5.95,10.42,6.05,10.42h40.27c0,0,12,0,6.05-10.42
		L38.86,12.38z"/>`,
	select: `<polyline vector-effect="non-scaling-stroke" points="10.5,21.75 33,44.25 55.5,21.75 	"/>`,
	mail: `<path vector-effect="non-scaling-stroke" d="M8.25,10.25c0,0-6,0-6,6v33.5c0,0,0,6,6,6h49.5c0,0,6,0,6-6v-33.5c0,0,0-6-6-6H8.25z"/>
		<path vector-effect="non-scaling-stroke" d="M3.3,12.63l25.14,18.79c0,0,4.81,3.59,9.59-0.03l24.65-18.71"/>`,
	info: `<circle vector-effect="non-scaling-stroke" cx="33" cy="33" r="31"/>
		<line vector-effect="non-scaling-stroke" x1="33" y1="53.64" x2="33" y2="23.28"/>
		<line vector-effect="non-scaling-stroke" x1="33" y1="18.24" x2="33" y2="12.36"/>`,
	close: `<line vector-effect="non-scaling-stroke" x1="10.5" y1="10.5" x2="55.5" y2="55.5"/>
		<line vector-effect="non-scaling-stroke" x1="55.5" y1="10.5" x2="10.5" y2="55.5"/>`,
	'arrow-right': `<polyline vector-effect="non-scaling-stroke" points="44.43,54.57 66,33 44.43,11.43" />
		<line vector-effect="non-scaling-stroke" x1="66" y1="33" x2="0" y2="33" />`,
	'arrow-down': `<polyline vector-effect="non-scaling-stroke" points="7.17,30.96 33,58 58.83,30.96 	"/>
		<line vector-effect="non-scaling-stroke" x1="33" y1="58" x2="33" y2="8"/>`
}
export default {
	props: {
		type: String
	},
	computed: {
		icon() {
			return icons[this.type]
		}
	}
}
</script>
