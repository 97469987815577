<template>
	<transition name="fade">
		<div class="geolocation-modal" v-if="loaded">
			<div class="geolocation-modal__blanket"></div>
			<div class="geolocation-modal__card card">
				<h2>{{ $t('message.front_geo_title') }}</h2>
				<p>{{ $t('message.front_geo_title_text') }}</p>
				<BasicButton
					buttonStyle="primary"
					:text="$t('message.front_geo_button')"
					@click="askGeolocationPermission"
				/>
				<div class="caption">{{ $t('message.front_geo_disclaimer') }}</div>
			</div>
		</div>
	</transition>

	<transition name="fade">
		<LoadingAnimation v-if="loaded && loadingGeolocation" :showCard="true" message="Looking up GPS data" />
	</transition>
	<transition name="fade">
		<ErrorModal
			v-if="loaded && showErrorModal"
			:title="$t('message.front_geo_error_title')"
			:message="$t('message.front_geo_error_text')"
			:caption="$t('message.front_geo_error_caption')"
			:button="false"
		/>
	</transition>
</template>

<script>
import BasicButton from '@/components/bits/BasicButton'
import ErrorModal from '@/components/modals/ErrorModal'
import LoadingAnimation from '@/components/bits/LoadingAnimation'

export default {
	name: 'GeolocationModal',
	components: {
		BasicButton,
		ErrorModal,
		LoadingAnimation
	},
	data() {
		return {
			loadingGeolocation: false,
			showErrorModal: false
		}
	},
	emits: ['granted', 'declined'],
	created() {
		this.checkGeolocationPermission()
	},
	methods: {
		checkGeolocationPermission() {
			//currently not available on Safari and iOS Safari
			if (navigator.permissions) {
				navigator.permissions.query({ name: 'geolocation' }).then((result) => {
					if (['granted', 'denied'].includes(result.state)) {
						this.askGeolocationPermission()
					}
				})
			}
			this.loaded = true
		},
		askGeolocationPermission() {
			if (navigator.geolocation) {
				setTimeout(() => {
					this.loadingGeolocation = true
				}, 500)
				const geoOptions = {
					timeout: 20_000
				}
				navigator.geolocation.getCurrentPosition(this.grantedGeolocation, this.declinedGeolocation, geoOptions)
			} else {
				this.$emit('declined')
			}
		},
		grantedGeolocation(position) {
			this.$emit('granted', position)
		},
		declinedGeolocation() {
			this.loadingGeolocation = false
			this.showErrorModal = true
			this.$emit('declined')
		}
	}
}
</script>
