import store from '@/store'
import axios from 'axios'
const debug = false

export const getParameters = async (uid, client) => {
	try {
		const { data } = await axios({
			url: process.env.VUE_APP_API_BASE_URL + '/getParameters',
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: {
				uid: uid,
				client: client
			}
		})

		return data
	} catch (error) {
		console.error(error.response)
		return new Error('Something went wrong.')
	}
}

let ApiCreateAttempts = 0
export const createTap = async () => {
	if (debug) return
	const db = store.state.db
	const senseData = store.state.senseData
	const urlParams = store.state.urlParams
	const coordinates = store.state.coordinates
	const isTestData = store.state.isTestData
	const isTemporaryData = store.state.isTemporaryData

	if (!db || !senseData.id) return alert('No data found.')
	if (!hasCoordinates()) setupCoordinatesWatcher()

	try {
		const { data } = await axios({
			url: process.env.VUE_APP_API_BASE_URL + '/createTap',
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: {
				db,
				urlParams,
				isTestData,
				isTemporaryData,
				...(hasCoordinates() && { coordinates })
			}
		})
		store.commit('setState', { key: 'tapId', value: data.tapId })
		console.log('Data properly saved.')
		ApiCreateAttempts++
	} catch (error) {
		//retry once if failed
		if (ApiCreateAttempts < 1) createTap({ coordinates })
		ApiCreateAttempts++

		console.log(error.response)
		//throw new Error('Something went wrong.')
	}
}

const setupCoordinatesWatcher = () => {
	const coordinates = new Promise((resolve, reject) => {
		store.watch(
			(state) => state.coordinates,
			async (newValue, oldValue) => {
				resolve(newValue)
			}
		)
	})
	const tapId = new Promise((resolve, reject) => {
		store.watch(
			(state) => state.tapId,
			async (newValue, oldValue) => {
				resolve(newValue)
			}
		)
	})
	Promise.all([coordinates, tapId]).then((values) => {
		updateTap()
	})
}

const hasCoordinates = () => {
	return store.state.coordinates.lat && store.state.coordinates.lon ? true : false
}

export const updateTap = async ({ references } = {}) => {
	const id = store.state.tapId
	if (!id) return false

	const coordinates = store.state.coordinates

	try {
		const { data } = await axios({
			url: process.env.VUE_APP_API_BASE_URL + '/updateTap',
			method: 'POST',
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded'
			},
			data: {
				db: store.state.db,
				id,
				...(references && { references }),
				...(hasCoordinates() && { coordinates })
			}
		})
		console.log('Data properly updated.')
	} catch (error) {
		console.log(error.response)
		//throw new Error('Something went wrong.')
	}
}
