<template>
	<GeolocationModal v-if="showGeolocationModal" @granted="geolocationGranted" />
	<router-view v-else-if="parametersLoaded" />
	<LoadingAnimation :message="$t('message.front_loading_data')" v-else />
</template>

<script>
import axios from 'axios'
import GeolocationModal from '@/components/modals/GeolocationModal'
import LoadingAnimation from '@/components/bits/LoadingAnimation'
import { getParameters, createTap, updateTap } from '@/compositions/useApiActions'

export default {
	name: 'home',
	components: {
		GeolocationModal,
		LoadingAnimation
	},
	data() {
		return {
			showGeolocationModal: false,
			ApiCreateAttempts: 0
		}
	},
	computed: {
		parameters() {
			return this.$store.state.parameters
		},
		parametersLoaded() {
			return this.$store.state.parametersLoaded
		},
		senseData() {
			return this.$store.state.senseData
		}
	},
	created() {
		this.getParameters()
	},
	methods: {
		geolocationGranted(geolocation) {
			const coordinates = {
				lat: geolocation.coords.latitude,
				lon: geolocation.coords.longitude,
				accuracy: geolocation.coords.accuracy
			}
			this.showGeolocationModal = false
			this.$store.commit('setState', { key: 'coordinates', value: coordinates })
			createTap()
		},
		async getParameters() {
			const uid = this.$store.state.senseData.id
			//const queryString = window.location.search
			//const urlParams = new URLSearchParams(queryString)
			//const uid = urlParams.get('u')
			if (!uid) return this.$store.commit('setState', { key: 'parametersLoaded', value: true })

			try {
				const data = await getParameters(uid, 'webapp')

				if (data.parameters.redirect) return this.redirectTo(data.parameters.redirect)

				this.$store.commit('setState', { key: 'parametersLoaded', value: true })
				this.$store.commit('setState', { key: 'db', value: data.db })
				this.$store.commit('setParameters', data.parameters)
				if (data.parameters.geolocation?.force) return (this.showGeolocationModal = true)

				// number (label) information neccessary? Probably nice to show
				// reference (label) information neccessary? Probably nice to show
				// sensor parameters neccessary to provide to charts (shockSense at least)

				createTap()
			} catch (error) {
				console.error(error.response)
				//throw new Error('Something went wrong.')
				this.$router.replace({ name: 'error-page', params: { errorType: 'label-not-activated' } })
			}
		},
		redirectTo(baseUrl) {
			const url = `${baseUrl}?${this.$store.state.senseData.url.q}&c=${this.$store.state.senseData.url.c}`
			window.location.href = url
		}
	}
}
</script>
