<template>
	<div id="app__main" :style="cssVars">
		<div id="app__content">
			<router-view />
		</div>
	</div>
</template>

<script>
export default {
	name: 'app',
	computed: {
		parameters() {
			return this.$store.state.parameters
		},
		cssVars() {
			return {
				'--identity-color-dark': this.parameters.color.dark,
				'--identity-color-light': this.parameters.color.light,
				'--identity-color-lightgrey': this.parameters.color.lightgrey,
				'--identity-color-grey': this.parameters.color.grey,
				'--identity-color-darkgrey': this.parameters.color.darkgrey,
				'--identity-color-primary': this.parameters.color.primary,
				'--identity-color-secundary': this.parameters.color.secundary,
				'--identity-color-tertiary': this.parameters.color.tertiary,
				'--identity-button-radius': this.parameters.button.radius,
				'--identity-button-color-primary':
					this.parameters.buttonColor?.primary ?? this.parameters.color.primary,
				'--identity-button-color-secundary':
					this.parameters.buttonColor?.secundary ?? this.parameters.color.secundary,
				'--identity-button-color-tertiary':
					this.parameters.buttonColor?.tertiary ?? this.parameters.color.tertiary,
				'--identity-button-text-color-primary':
					this.parameters.buttonTextColor?.primary ?? this.parameters.color.dark,
				'--identity-button-text-color-secundary':
					this.parameters.buttonTextColor?.secundary ?? this.parameters.color.dark,
				'--identity-button-text-color-tertiary':
					this.parameters.buttonTextColor?.tertiary ?? this.parameters.color.dark
			}
		}
	}
}
</script>
