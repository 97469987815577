import messages from '@/i18n/messages.csv'
const getMessagesFromCSV = (languagecode) =>
	messages.reduce((obj, item) => ((obj[item.key] = item[languagecode]), obj), {})

export default {
	en: {
		description: 'English',
		message: getMessagesFromCSV('en')
	},
	pt: {
		description: 'Portuguese',
		message: getMessagesFromCSV('pt')
	}
}
