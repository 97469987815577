<template>
	<router-link v-if="to" :to="to" class="button" :class="['--' + buttonStyle]"
		>{{ text }} <Icon type="arrow-right"
	/></router-link>
	<a v-else :href="href" :target="target" class="button" :class="['--' + buttonStyle]"
		>{{ text }} <Icon type="arrow-right"
	/></a>
</template>

<script>
import Icon from '@/components/bits/Icon'

export default {
	name: 'BasicButton',
	components: {
		Icon
	},
	props: {
		to: String,
		href: String,
		text: String,
		buttonStyle: String,
		target: String
	}
}
</script>
