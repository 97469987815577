import { createI18n } from 'vue-i18n'
import messages from './messages'

const i18n = createI18n({
	legacy: true,
	locale: navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0],
	fallbackLocale: 'en',
	messages: messages
})

export default i18n
