import Vuex from 'vuex'

export default Vuex.createStore({
	state: {
		isTestData: false,
		isTemporaryData: false,
		db: null,
		tapId: null,
		senseData: {},
		senseTestData: {
			type: 'tempSense',
			id: undefined,
			startTime: 0,
			elapsedTime: 0,
			dataPointsCount: 0,
			interval: 0,
			dataPoints: [],
			currentTemp: 0,
			checksum: null
		},
		urlParams: null,
		senseDataTimestamps: [],
		temparatureRange: {
			min: -10,
			max: 40
		},
		coordinates: {
			lat: null,
			lon: null,
			accuracy: null
		},
		parametersLoaded: false,
		parameters: {
			logo: {
				url: null
			},
			color: {
				dark: '#2B2B2B',
				light: '#ffffff',
				lightgrey: '#f0f1f1',
				grey: '#dddddd',
				darkgrey: '#AAAAAA',
				primary: '#30e691',
				secundary: '#30e691',
				tertiary: '#30e691'
			},
			button: {
				radius: '3px'
			},
			uiElements: {},
			geolocation: {
				active: false,
				force: false
			}
		}
	},
	mutations: {
		setState(state, payload) {
			state[payload.key] = payload.value
		},
		setParameter(state, payload) {
			state.parameters[payload.type] = {
				...state.parameters[payload.type],
				...payload.values
			}
		},
		setParameters(state, payload) {
			state.parameters = mergeDeep(state.parameters, payload)
		}
	},
	actions: {}
})

const mergeDeep = (target, ...sources) => {
	if (!sources.length) return target
	const source = sources.shift()

	const isObject = (item) => {
		return item && typeof item === 'object' && !Array.isArray(item)
	}

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key])
					Object.assign(target, {
						[key]: {}
					})
				mergeDeep(target[key], source[key])
			} else {
				Object.assign(target, {
					[key]: source[key]
				})
			}
		}
	}

	return mergeDeep(target, ...sources)
}
