<template>
	<div class="loading">
		<div class="loading__blanket"></div>
		<div class="loading__card" :class="{ card: showCard }">
			<div class="loading__animation">
				<div></div>
				<div></div>
			</div>
			<div class="loading__message">{{ message }}</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoadingAnimation',
	props: {
		message: String,
		showCard: {
			type: Boolean,
			default: false
		}
	}
}
</script>
