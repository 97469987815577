import { createWebHistory, createRouter } from 'vue-router'
import Home from './views/Home.vue'

const routes = [
	{
		path: '/:hash?',
		name: 'home',
		component: Home,
		children: [
			{
				name: 'base',
				path: '/:hash?',
				component: () => import(/* webpackChunkName: "sense-data" */ './views/SenseData.vue'),
				meta: {
					showLogo: true,
					requiresSenseData: true
				}
			},
			{
				name: 'display-data-test',
				path: 'display-data-test',
				component: () => import(/* webpackChunkName: "sense-data" */ './views/SenseData.vue'),
				meta: {
					showLogo: true,
					requiresSenseData: false
				}
			}
		]
	},
	{
		path: '/something-went-wrong',
		name: 'error-page',
		component: () => import(/* webpackChunkName: "error" */ './views/Error.vue')
	}
]

const router = createRouter({
	history: createWebHistory(),
	routes
})

export default router
