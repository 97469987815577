import { createApp, ref } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

import '@/less/style.less'
import { decodeUrlParams } from '@tapp.online/cnnct-cloud-decode'

const app = createApp(App)

Sentry.init({
	app,
	dsn: 'https://b71fc64564ba4f1fa3f629b7d3e70644@o1211307.ingest.sentry.io/6347479',
	integrations: [
		new BrowserTracing({
			routingInstrumentation: Sentry.vueRouterInstrumentation(router),
			tracingOrigins: ['localhost', 'sense.tpp.onl', /^\//]
		})
	],
	tracesSampleRate: 1.0,
	logErrors: true
})

app.use(router).use(store).use(i18n).mount('#app')

router.beforeEach((to, from, next) => {
	if (to.query.u) {
		const urlParamsObject = to.query
		const urlParams = new URLSearchParams(to.query)
		const isTestData = urlParams.get('isTestData')
		const isTemporaryData = urlParams.get('isTemporaryData')
		urlParams.delete('isTestData')
		urlParams.delete('isTemporaryData')

		try {
			const { query, senseData, senseDataTimestamps } = decodeUrlParams(urlParamsObject)

			store.commit('setState', { key: 'isTestData', value: isTestData })
			store.commit('setState', { key: 'isTemporaryData', value: isTemporaryData })
			store.commit('setState', { key: 'senseData', value: senseData })
			store.commit('setState', { key: 'urlParams', value: urlParams.toString() })
			store.commit('setState', { key: 'senseDataTimestamps', value: senseDataTimestamps })

			//check if test query parameter is true or not to preserve link for testing purposes
			if (!isTestData) router.replace('/')
		} catch (error) {
			console.error(error)
		}
	}

	if (to.matched.some((route) => route.meta.requiresSenseData)) {
		//next()

		if (store.state.senseData.id) {
			next()
		} else {
			router.replace({ name: 'error-page', params: { errorType: 'insufficient-data' } })
		}
	} else {
		next()
	}
})
